import 'react-tabs/style/react-tabs.css'
import 'leaflet/dist/leaflet.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Mail, MapPin, Phone, Printer } from 'react-feather'
import LazyLoad from 'react-lazyload'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import Fade from 'react-reveal/Fade'

export interface Props {
  address?: string
  bookingOffice?: string
  email?: string
  fax?: string
  image?: { [key: string]: any }
  languageCode: string
  latitude?: string
  longitude?: string
  phone?: string
  siteName?: string
  title?: string
  zoom?: string
  whatsapp?: string
}

export const Map = memo(function Map({
  address,
  bookingOffice,
  email,
  fax,
  image,
  languageCode,
  latitude,
  longitude,
  phone,
  siteName,
  title,
  zoom,
  whatsapp,
}: Props) {
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: window.innerWidth > 1023 ? [60, 60] : [30, 30],
        iconAnchor: window.innerWidth > 1023 ? [30, 60] : [15, 30],
        html: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035 c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/></svg>',
      }),
    )
  }, [])

  return (
    <Container>
      <Wrapper row>
        {image ? (
          <Background>
            <LazyLoad>
              <Image {...image} />
            </LazyLoad>
          </Background>
        ) : null}
        <Text>
          {title ? (
            <Fade bottom>
              <Title>{title}</Title>
            </Fade>
          ) : null}
          <Fade bottom>
            <>
              {address ? (
                <Address>
                  <MapPin />
                  {address}
                </Address>
              ) : null}
              {phone ? (
                <ContactsItem>
                  <Anchor
                    href={`tel:${phone}`}
                    onClick={() => {
                      if (isMobile) {
                        typeof window.gtag !== 'undefined' &&
                          window.gtag('event', 'Click', {
                            event_category: 'Website',
                            event_label: 'Mobile Phone',
                          })
                      }
                    }}
                  >
                    <Phone />
                    {phone}
                  </Anchor>
                </ContactsItem>
              ) : null}
              {bookingOffice ? (
                <ContactsItem>
                  <Anchor
                    href={`tel:${bookingOffice}`}
                    onClick={() => {
                      if (isMobile) {
                        typeof window.gtag !== 'undefined' &&
                          window.gtag('event', 'Click', {
                            event_category: 'Website',
                            event_label: 'Mobile Phone',
                          })
                      }
                    }}
                  >
                    <Phone />
                    {`${bookingOffice} (${useVocabularyData(
                      'booking-office',
                      languageCode,
                    )})`}
                  </Anchor>
                </ContactsItem>
              ) : null}
              {whatsapp ? (
                <ContactsItem>
                  <Anchor
                    href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.16"
                      height="20.4"
                      viewBox="0 0 20.16 20.4"
                    >
                      <path
                        d="M-658.849-8242.677a.486.486,0,0,1-.12-.521l1.6-4.338a10.046,10.046,0,0,1-1.552-6.188,9.6,9.6,0,0,1,2.769-6.019,9.56,9.56,0,0,1,6.808-2.8,10.023,10.023,0,0,1,7.124,2.972,9.866,9.866,0,0,1,.172,13.934,9.554,9.554,0,0,1-6.807,2.8h0a9.992,9.992,0,0,1-5.293-1.517l-4.179,1.773a.5.5,0,0,1-.19.038A.488.488,0,0,1-658.849-8242.677Zm5.019-2.631a9.033,9.033,0,0,0,4.979,1.5,8.589,8.589,0,0,0,6.12-2.516,8.891,8.891,0,0,0-.172-12.557,9.051,9.051,0,0,0-6.436-2.687,8.589,8.589,0,0,0-6.12,2.515,8.838,8.838,0,0,0-.96,11.185.488.488,0,0,1,.053.441l-1.3,3.512,3.378-1.434a.494.494,0,0,1,.19-.038A.481.481,0,0,1-653.831-8245.308Zm7.831-1.679a8.946,8.946,0,0,1-5.868-2.855l-.01-.01a8.947,8.947,0,0,1-2.856-5.867,2.935,2.935,0,0,1,.99-2.034,2.771,2.771,0,0,1,1.547-.864c.707,0,.9.334,1.18.84.069.124.148.264.25.433.056.094.134.212.216.337.522.8.851,1.352.672,1.817a2.636,2.636,0,0,1-.873.946,4.524,4.524,0,0,0-.409.352,5.039,5.039,0,0,0,1.371,1.962,5.046,5.046,0,0,0,1.961,1.371,4.646,4.646,0,0,0,.349-.407,2.643,2.643,0,0,1,.95-.875.779.779,0,0,1,.273-.048,3.343,3.343,0,0,1,1.544.72c.125.082.243.16.338.217.168.1.308.18.431.25.506.284.84.473.84,1.18a2.773,2.773,0,0,1-.865,1.547,2.938,2.938,0,0,1-2.033.99Zm-7.76-8.733a8.1,8.1,0,0,0,2.575,5.185,8.112,8.112,0,0,0,5.185,2.576,2.6,2.6,0,0,0,1.925-1.573.779.779,0,0,0-.007-.118,3.112,3.112,0,0,0-.337-.205c-.123-.069-.276-.155-.457-.264-.11-.066-.236-.149-.369-.236a5.432,5.432,0,0,0-.975-.555,3.816,3.816,0,0,0-.5.551c-.334.418-.622.779-1.029.781-.99,0-2.34-1.274-2.73-1.664s-1.663-1.74-1.664-2.729c0-.409.363-.7.783-1.033a3.748,3.748,0,0,0,.548-.5,5.415,5.415,0,0,0-.554-.974c-.087-.134-.17-.259-.236-.368-.109-.182-.2-.335-.265-.458a3.012,3.012,0,0,0-.205-.337.806.806,0,0,0-.118-.008A2.592,2.592,0,0,0-653.76-8255.72Z"
                        transform="translate(659.208 8262.742)"
                        fill={theme.colors.variants.primaryDark1}
                        stroke={theme.colors.variants.primaryDark1}
                        strokeWidth="0.2"
                      />
                    </svg>
                    {whatsapp}
                  </Anchor>
                </ContactsItem>
              ) : null}
              {fax ? (
                <ContactsItem>
                  <Fax>
                    <Printer />
                    {fax}
                  </Fax>
                </ContactsItem>
              ) : null}
              {email ? (
                <ContactsItem>
                  <Email
                    href={`mailto:${email}`}
                    onClick={() => {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Email Address',
                        })
                    }}
                  >
                    <Mail />
                    {email}
                  </Email>
                </ContactsItem>
              ) : null}
            </>
          </Fade>
        </Text>
      </Wrapper>
      <MapBody>
        <LazyLoad>
          <Image {...image} />
        </LazyLoad>
        <MapWrapper>
          {typeof window !== undefined && latitude && longitude ? (
            <MapContainer
              center={[Number(latitude), Number(longitude)]}
              dragging={L.Browser && L.Browser.mobile ? false : true}
              zoom={Number(zoom)}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              />
              <Marker
                icon={markerIcon}
                position={[Number(latitude), Number(longitude)]}
              >
                {siteName ? <Popup>{siteName}</Popup> : null}
              </Marker>
            </MapContainer>
          ) : null}
        </MapWrapper>
      </MapBody>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem auto 15.75rem;
  padding: 6rem 0;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 4.5rem;
    left: 4.5rem;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto 11.25rem;
    padding: 3.75rem 0;
    &:before {
      right: 1.5rem;
      left: 1.5rem;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 6rem;
    padding: 0;
    &:before {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)``

const Background = styled.div`
  width: 40%;
  padding-bottom: 48%;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Text = styled.div`
  margin-left: 6.458vw;
  padding-right: 6.458vw;
  position: relative;
  z-index: 4;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    padding: 0 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 2.9375rem;
`

const ContactsItemStyle = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.variants.neutralDark4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  &:last-of-type {
    margin-top: 0;
  }
  svg {
    width: 1.25rem;
    height: auto;
    stroke: ${theme.colors.variants.primaryDark1};
    stroke-width: 1.5;
    margin-right: 1rem;
  }
`

const ContactsItem = styled.div``

const Address = styled.div`
  ${ContactsItemStyle}
`

const Anchor = styled.a`
  ${ContactsItemStyle}
  &:hover {
    text-decoration: underline;
  }
`

const Fax = styled.div`
  ${ContactsItemStyle}
`

const Email = styled.a`
  ${ContactsItemStyle}
  &:hover {
    text-decoration: underline;
  }
`

const MapBody = styled.div`
  width: calc(100% - 12.639vw);
  height: 100%;
  position: absolute;
  top: 6rem;
  left: 0;
  .lazyload-wrapper {
    > img {
      width: calc(40% - 5vw);
      height: calc(100% - 10vw - 12rem);
      object-fit: cover;
      position: absolute;
      top: 5vw;
      left: 5vw;
      z-index: 3;
    }
  }

  @media (max-width: 1399px) {
    top: 12.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 50vh;
    margin-top: 1.5rem;
    position: relative;
    top: auto;

    .lazyload-wrapper {
      display: none;
    }
  }
`

const MapWrapper = styled.div`
  width: 68%;
  padding-bottom: 40%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    .leaflet-left {
      display: none;
    }
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .leaflet-marker-icon {
    background: none;
    border: 0;
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }
  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        color: ${({ theme }) => theme.colors.variants.neutralDark1};
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-weight: 100;
        font-size: 2.375rem;
        border: 0;
        border-radius: 0;
        transition: 0.3s ease-out;
        &.leaflet-control-zoom-in {
          order: 2;
        }
        &.leaflet-disabled {
          opacity: 0.6;
          pointer-events: none;
        }
        &:hover {
          background: ${({ theme }) => theme.colors.variants.neutralLight3};
        }
      }
    }
  }
  .leaflet-bottom {
    display: none;
  }
  .leaflet-popup {
    .leaflet-popup-content-wrapper {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      box-shadow: none;
      border-radius: 0;
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1rem;
      padding: 1rem;
    }
    .leaflet-popup-content {
      line-height: 1.5rem;
      margin: 0;
    }
    .leaflet-popup-tip {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      box-shadow: none;
    }
    .leaflet-popup-close-button {
      display: none;
    }
  }
`
